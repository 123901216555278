
import * as d from './proxies';

export const DIRECTIVES = [
  d.IonAccordion,
  d.IonAccordionGroup,
  d.IonApp,
  d.IonAvatar,
  d.IonBackButton,
  d.IonBackdrop,
  d.IonBadge,
  d.IonBreadcrumb,
  d.IonBreadcrumbs,
  d.IonButton,
  d.IonButtons,
  d.IonCard,
  d.IonCardContent,
  d.IonCardHeader,
  d.IonCardSubtitle,
  d.IonCardTitle,
  d.IonCheckbox,
  d.IonChip,
  d.IonCol,
  d.IonContent,
  d.IonDatetime,
  d.IonDatetimeButton,
  d.IonFab,
  d.IonFabButton,
  d.IonFabList,
  d.IonFooter,
  d.IonGrid,
  d.IonHeader,
  d.IonIcon,
  d.IonImg,
  d.IonInfiniteScroll,
  d.IonInfiniteScrollContent,
  d.IonInput,
  d.IonItem,
  d.IonItemDivider,
  d.IonItemGroup,
  d.IonItemOption,
  d.IonItemOptions,
  d.IonItemSliding,
  d.IonLabel,
  d.IonList,
  d.IonListHeader,
  d.IonMenu,
  d.IonMenuButton,
  d.IonMenuToggle,
  d.IonNav,
  d.IonNavLink,
  d.IonNote,
  d.IonProgressBar,
  d.IonRadio,
  d.IonRadioGroup,
  d.IonRange,
  d.IonRefresher,
  d.IonRefresherContent,
  d.IonReorder,
  d.IonReorderGroup,
  d.IonRippleEffect,
  d.IonRow,
  d.IonSearchbar,
  d.IonSegment,
  d.IonSegmentButton,
  d.IonSelect,
  d.IonSelectOption,
  d.IonSkeletonText,
  d.IonSlide,
  d.IonSlides,
  d.IonSpinner,
  d.IonSplitPane,
  d.IonTabBar,
  d.IonTabButton,
  d.IonText,
  d.IonTextarea,
  d.IonThumbnail,
  d.IonTitle,
  d.IonToggle,
  d.IonToolbar
];
